(function(w,t,c,p,s,e){p=new Promise(function(r){w[c]={client:function(){if(!s){
    s=document.createElement(t);s.src='https://js.cobrowse.io/CobrowseIO.js';s.async=1;
    e=document.getElementsByTagName(t)[0];e.parentNode.insertBefore(s,e);s.onload=function()
    {r(w[c]);};}return p;}};});})(window,'script','CobrowseIO');

    CobrowseIO.license = cobrowse_key;    
    CobrowseIO.client().then(function(){
        CobrowseIO.start();
            // Additional data
            // CobrowseIO.customData = {
            //     user_id: (ub.user) ? ub.user.id : '',
            //     user_name: (ub.user) ? ub.user.fullname : 'Guest User',
            //     user_email: (ub.user) ? ub.user.email : ''
            // };
    console.log(CobrowseIO.customData)
});